import React from 'react';
import PropTypes from 'prop-types';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Stack, IconButton } from '@mui/material';
import { FETCH_INVOICE_DOCUMENT } from '@fingo/lib/graphql';
import { useMutation } from '@apollo/client';

const PDFDownloadCell = ({ documents }) => {
  const invoiceDocument = documents.filter((doc) => doc.type.code === 'PDF')?.[0] ?? null;
  const [fetchInvoiceDocument] = useMutation(FETCH_INVOICE_DOCUMENT, {
    variables: { id: invoiceDocument?.id },
    onCompleted: (data) => window.open(data.fetchInvoiceDocument.url),
  });
  return (
    <Stack width="100%" direction="column" alignItems="center">
      <IconButton
        color="primary"
        onClick={fetchInvoiceDocument}
        disabled={invoiceDocument === null}
      >
        <PictureAsPdfIcon />
      </IconButton>
    </Stack>
  );
};

PDFDownloadCell.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.shape({
        id: PropTypes.string,
        code: PropTypes.string,
      }),
    }),
  ),
};

PDFDownloadCell.defaultProps = {
  documents: [],
};

export default PDFDownloadCell;
