import React from 'react';
import PropTypes from 'prop-types';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Stack } from '@mui/material';
import { SEND_ROSTER_INVOICE_DOCUMENTS } from '@fingo/lib/graphql';
import { useLazyQuery } from '@apollo/client';
import { useSnackBars } from '@fingo/lib/hooks';
import { LoadingIconButton } from '@fingo/lib/components/buttons';

const PDFDownloadCell = ({ id }) => {
  const { addAlert } = useSnackBars();
  const [fetchInvoiceDocument, { loading }] = useLazyQuery(SEND_ROSTER_INVOICE_DOCUMENTS, {
    variables: { id },
    onCompleted: () => addAlert({
      severity: 'success',
      message: 'Nómina enviada al mail',
    }),
  });
  return (
    <Stack width="100%" direction="column" alignItems="center">
      <LoadingIconButton
        color="primary"
        onClick={fetchInvoiceDocument}
        loading={loading}
      >
        <PictureAsPdfIcon />
      </LoadingIconButton>
    </Stack>
  );
};

PDFDownloadCell.propTypes = {
  id: PropTypes.string.isRequired,
};

export default PDFDownloadCell;
