import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { GET_ROSTERS } from '@fingo/lib/graphql';
import { Typography } from '@mui/material';
import React from 'react';
import fundsDownloadColumns from '../../constants/funds-download-columns';
import FundsDownloadActions from './FundsDownloadActions';

const FundsDownload = () => {
  const includingColumns = [
    'pk',
    'fund',
    'cessionFund',
    'invoiceCount',
    'totalAmount',
    'status',
    'details',
    'receed',
    'certificate',
    'pdf',
  ];
  return (
    <FingoMainView
      id="funds"
      query={GET_ROSTERS}
      slots={{
        header: PaperHeader,
        actions: FundsDownloadActions,
        table: FingoDataGrid,
      }}
      slotProps={{
        header: {
          viewTitle: 'Nóminas de descarga en fondos',
          finder: {
            searchPlaceHolder: 'Buscar empresa',
          },
        },
        table: {
          includeHeaders: includingColumns,
          columns: fundsDownloadColumns,
          initialOrderBy: '-pk',
          noRowsMessage: () => <Typography>No hay Descarga de Fondos Pendientes</Typography>,
          rowsPerPageOptions: [10, 25, 50, 100],
        },
      }}
    />
  );
};

export default FundsDownload;
