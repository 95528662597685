import React, { useState, useCallback } from 'react';
import { Button, DialogContent, Typography, Stack, Box } from '@mui/material';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import UploadDropZoneDialogContainer from '@fingo/lib/components/containers/UploadDropZoneDialogContainer';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@apollo/client';
import { REQUEST_INVOICE_ROSTER_RECESION } from '@fingo/lib/graphql';

const RequestInvoiceRecesionDialog = () => {
  const { addAlert } = useSnackBars();
  const [error, setError] = useState(null);
  const [open, toggleOpen] = useBooleanState();
  const [files, setFiles] = useState([{ name: '' }]);
  const handleClose = useCallback(() => {
    setFiles([{ name: '' }]);
    toggleOpen();
  }, []);
  const [requestInvoiceRosterRecesion,
    { loading: loadingUpload },
  ] = useMutation(REQUEST_INVOICE_ROSTER_RECESION, {
    variables: {
      file: files[0],
    },
    onCompleted: handleClose,
    onError: (err) => addAlert({
      id: 'receive-recesions-error',
      severity: 'error',
      message: `Error al recibir la recesión de facturas: ${err}`,
    }),
  });
  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={toggleOpen}
      >
        Recibir Recesión de Facturas
      </Button>
      <FingoDialog
        open={open}
        handleClose={handleClose}
        title="Recesion de Facturas desde el Fondo"
        dialogActionButton={(
          <LoadingButton
            size="small"
            color="primary"
            variant="contained"
            onClick={requestInvoiceRosterRecesion}
            loading={loadingUpload}
            disabled={files[0].name === ''}
          >
            Subir nómina
          </LoadingButton>
        )}
      >
        <DialogContent>
          <Stack direction="column" spacing={1}>
            <Stack direction="column" spacing={0.8}>
              <Typography variant="h2">
                Selecciona el archivo .xlsx con los campos:
              </Typography>
              <Box sx={{ fontFamily: 'Monospace' }}>invoice_id</Box>
              <Typography variant="h2">
                Las facturas deben estar cedidas al fondo
              </Typography>
            </Stack>
            <UploadDropZoneDialogContainer
              files={files}
              setFiles={setFiles}
              errorMutation={error}
              setErrorMutation={setError}
            />
          </Stack>
        </DialogContent>
      </FingoDialog>
    </>
  );
};

export default RequestInvoiceRecesionDialog;
