import React, { useState } from 'react';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { GET_LOAN_MANAGERS } from '@fingo/lib/graphql';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import rosterDetailColumns from '../../constants/roster-detail-columns';
import RosterDetailActions from './RosterDetailActions';

const RosterDetail = () => {
  const { rosterId } = useParams();
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const includingColumns = [
    'document_Folio',
    'document_Company_MasterEntity_Name',
    'document_Receiver_Name',
    'dates',
    'financedAmount',
    'assignmentStatus',
    'certificate',
  ];
  return (
    <FingoMainView
      id="roster-detail"
      query={GET_LOAN_MANAGERS}
      queryCustomVariables={{
        invoiceLoanRosterId: parseInt(rosterId, 10),
      }}
      slots={{
        header: PaperHeader,
        actions: RosterDetailActions,
        table: FingoDataGrid,
      }}
      slotProps={{
        header: {
          viewTitle: 'Detalle de Nómina',
          showGoBack: true,
        },
        table: {
          checkboxSelection: true,
          includeHeaders: includingColumns,
          columns: rosterDetailColumns,
          onSelectionModelChange: setSelectedDocuments,
          initialOrderBy: 'document_Company_MasterEntity_Name',
          noRowsMessage: <Typography>No hay Detalle de la Nómina</Typography>,
          rowsPerPageOptions: [100],
        },
        actions: {
          selectedDocuments,
          setSelectedDocuments,
        },
      }}
    />
  );
};

export default RosterDetail;
